<template>

    <div>
    
        <div class="container-lg">
            <div class="auth-container">
                <div class="auth-form">

                    <router-link to="/" title="Go back to the homepage">
                        <img class="auth-form-logo" src="@/assets/img/sabasi-logo-final.png" alt="Sabasi Logo" />
                    </router-link>

                    <!-- Alert message(s) for errors -->
                    <div class="alert alert-danger" v-if="error">{{ error }}</div>                    

                    <form>
                            <!-- Email input -->
                            <div class="mb-3 form-group" :class="{ 'form-group--error': $v.auth.email.$error }">
                                <label class="form-label form__label" for="user_email">Email</label>
                                <input class="form__input  form-control" :class="{'form-control--error': $v.auth.email.$error }" v-model.trim="$v.auth.email.$model" v-on:input="$v.auth.email.$touch" />
                                <div v-if="$v.auth.email.$dirty" class="mt-2">
                                    <span class="input-error" v-if="!$v.auth.email.required">
                                        <font-awesome-icon icon="exclamation-circle"/>
                                        Email is required
                                    </span>
                                    <span class="input-error" v-if="!$v.auth.email.email">
                                        <font-awesome-icon icon="exclamation-circle"/>
                                        Please enter a valid email address
                                    </span>
                                </div>
                            </div><!-- End email input -->

                            <!-- Password input -->
                            <div class="mb-3 form-group" :class="{ 'form-group--error': $v.auth.password.$error }">
                                <label class="form-label form__label" for="user_pass">Password</label>
                                <div class="input-has-toggle">
                                    <input spellcheck="false" class="form__input form-control" :class="{'form-control--error': $v.auth.password.$error }" v-model.trim="$v.auth.password.$model" v-on:input="$v.auth.password.$touch" v-bind:type="[showPassword ? 'text' : 'password']"/>
                                    <span v-if="auth.password" class="input-toggle" @click="showPassword = !showPassword">
                                        <font-awesome-icon v-if="!showPassword" icon="eye-slash"/>
                                        <font-awesome-icon v-else icon="eye"/>
                                    </span>
                                </div>
                                <div v-if="$v.auth.password.$dirty">
                                    <div class="mt-2" v-if="!$v.auth.password.required">
                                        <span class="input-error">
                                            <font-awesome-icon icon="exclamation-circle"/>
                                            Password is required.
                                        </span>
                                    </div>
                                    <div class="mt-2" v-if="!$v.auth.password.minLength">
                                        <span class="input-error">
                                            <font-awesome-icon icon="exclamation-circle"/>
                                            Password must have at least {{ $v.auth.password.$params.minLength.min }} characters.
                                        </span>
                                    </div>
                                </div>
                            </div><!-- End password input -->

                            <!-- Recaptcha -->
                            <div class="form-group">
                                <vue-recaptcha
                                    ref="recaptcha"
                                    size="invisible"
                                    @verify="onVerified"
                                    @expired="onExpired"
                                    :sitekey="sitekey">
                                </vue-recaptcha>
                            </div><!-- End recaptcha -->

                            <!-- Sign in button -->
                            <div class="mb-3">
                                <button type="submit" @click.prevent="login()" class="custom-btn-lg custom-btn-secondary custom-btn-full" :disabled="wait">
                                    <span v-if="!wait">Sign In</span>
                                    <span v-else>Please wait...</span>
                                </button>
                            </div><!-- End sign in button -->

                    </form><!-- End login form -->

                    <!-- Divider -->
                    <div class="login-link-divider mb-3">or</div>

                    <!-- Other login options -->
                    <ul class="login-options">                                
                        <li>
                            <a :href="toGoogle" class="social-link" title="Sign in to Sabasi with your Google account">
                                <!--<font-awesome-icon :icon="['fab', 'google']" /> -->
                                <svg class="social-icon-svg" viewBox="0 0 48 48"><path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/></svg>
                                Sign in with Google
                            </a>
                        </li>                                
                    </ul><!-- End login options -->

                    <div v-html="google_auth"></div>

                    <p class="login-link-small">
                        New to Sabasi?
                        <router-link to="/register"><a>Create an account</a></router-link>
                    </p>
                                
                    <p class="login-link-small">
                        Lost your password?
                        <router-link to="/forgot-password">Reset it here</router-link>
                    </p>
                </div><!-- End auth-form -->   

                <!-- Auth footer -->
                <div class="auth-footer">
                    <ul>
                        <li><router-link to="/">Sabasi</router-link></li>
                        <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                        <li><router-link to="/">Terms &amp; Conditions</router-link></li>
                    </ul>
                </div> 

            </div><!-- End auth-container -->
        </div><!-- End container-lg -->

    </div>

</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import {required, minLength, email} from 'vuelidate/lib/validators'

export default {
    name: "Login",
    components: {VueRecaptcha},
    data() {
        return {
            showPassword: false,
            google_auth: "",
            auth: {
                email: "",
                password: "",
                recaptcha_token: '',
                token: ''
            },
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        };
    },
    validations: {
        auth: {
            email: {
                required,
                email: email
            },
            password: {
                required,
                minLength: minLength(8)
            }
        }
    },
    computed: {
        toGoogle() {
            return process.env.VUE_APP_URL + '/auth/redirect'
        }
    },
    methods: {
        login() {
            this.$refs.recaptcha.execute();
        },
        onExpired: function () {
            this.$refs.recaptcha.reset();
        },
        onVerified: function (token) {
            this.auth.recaptcha_token = token
            this.doLogin()
        },
        doLogin() {
            this.wait = true
            this.$http.post("/api/token", this.auth).then((response) => {
                let user = response.data.data.user;
                localStorage.setItem(process.env.VUE_APP_NAME + "_token", response.data.data.token);
                localStorage.setItem(process.env.VUE_APP_NAME + "_user", JSON.stringify(user));

                this.$toaster.success('Welcome to your dashboard...');
                window.location.href = '/dashboard';
            }).catch((error) => {
                this.showError(error);
            }).then(() => {
                this.wait = false;
            });
        }
    },
};
</script>

<style lang="css" scoped>
</style>